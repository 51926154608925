@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  -webkit-font-smoothing: antialiased;
}

.customInput {
 @apply block h-12 px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded border border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-primary
}

.customLabel {
  @apply absolute cursor-text text-sm font-medium text-gray-400 duration-300 transform -translate-y-5 scale-90 top-2 z-10 bg-white px-2 left-1
}

.btn-primary {
  @apply flex items-center  text-white bg-primary focus:outline-none font-medium rounded-lg text-sm px-4 py-2.5 mb-3 text-center hover:bg-primaryDark transition-all;
}